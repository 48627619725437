
import React from "react"

import OnsorderPage from "../../components/negosyonow/controls/onsordereditor";

const BusshipOrderPage = ({location}) => {
	return <OnsorderPage
				location={location}
				methodid={4}
			/>
}


export default BusshipOrderPage;
